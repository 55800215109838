import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import Skeleton from 'components/Skeleton/Skeleton';
import { useElementOnScreen } from 'hooks/useElementOnScreen';

import styles from './card.module.scss';

import { fetchTags, fetchActivites } from 'global/queries';
import { fullSurfaceFormat, priceWithUnitFormat } from 'global/util';

import { ReactComponent as Dots } from 'assets/three-dots.svg';
import { defaultPictureName } from 'global/util';

const AnnonceCard = ({
  annonce,
  asLead = false,
  showTags = true,
  hover,
  setHover
}) => {
  let data = { ...annonce };
  annonce = data.annonce || annonce;
  let date_send = null;
  let message = null;
  const [currentHover, setCurrentHover] = useState(false);

  if (asLead) {
    message = data.message;
    let _date = new Date(data.date_send);
    date_send =
      _date
        .toLocaleDateString('fr', {
          day: 'numeric',
          month: 'short',
          year: '2-digit'
        })
        .toUpperCase() +
      ' à ' +
      _date.getHours() +
      'h' +
      _date.getMinutes().toString().padStart(2, '0');
  }
  let ctc = (
    <Content
      annonce={annonce}
      date_send={date_send}
      showTags={showTags}
      message={message}
    />
  );

  const handleMouseEnter = () => {
    if (annonce.url) {
      setHover(true);
      setCurrentHover(true);
    }
  };
  const handleMouseLeave = () => {
    if (annonce.url) {
      setHover(false);
      setCurrentHover(false);
    }
  };

  return annonce.url ? (
    <a
      href={`${process.env.REACT_APP_SITE_URL + annonce.url}`}
      className={`${styles.annonce} ${
        hover && !currentHover ? styles.isHover : ''
      }`}
      target="_blank"
      rel="noreferrer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {ctc}
    </a>
  ) : (
    <div
      className={`${styles.annonce} ${styles.annonce_off} ${
        hover && !currentHover ? styles.isHover : ''
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {ctc}
    </div>
  );
};

const Content = ({ annonce, date_send, message, showTags = true }) => {
  const [wasVisible, setWasVisible] = useState(false);

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 1.0
  });

  useEffect(() => {
    if (isVisible) setWasVisible(true);
  }, [isVisible]);

  const { data: dataTags, status: statusTags } = useQuery(
    ['fetchTags', annonce.id],
    () => fetchTags('fetchTags', annonce.id),
    {
      enabled: annonce.nature != 'FON' && showTags && wasVisible,
      refetchOnWindowFocus: false
    }
  );
  const { data: dataActivites, status: statusActivites } = useQuery(
    ['fetchActivites', annonce.id],
    () => fetchActivites('fetchActivites', annonce.id),
    {
      enabled: annonce.nature === 'FON' && showTags && wasVisible,
      refetchOnWindowFocus: false
    }
  );

  let surface_content =
    fullSurfaceFormat(
      annonce.surface_min,
      annonce.surface,
      annonce.divisible,
      true
    ) ?? 'N.C';

  let price_content =
    priceWithUnitFormat(
      annonce.type,
      annonce.nature == 'FON' ? null : annonce.tarif_m2,
      annonce.tarif
    ) ?? 'N.C';

  return (
    <>
      {date_send && (
        <div className={styles.lead}>
          <div className={styles.date_send}>
            <div className={styles.date_lib}>Demande envoyée le</div>
            <div className={styles.date_text}>{date_send}</div>
          </div>
          {annonce.id_partenaire && (
            <div className={styles.logo}>
              <img
                src={`${process.env.REACT_APP_IMAGE_PATH}/partenaires/logos/${annonce.id_partenaire}_on.jpg`}
                alt="logo-partenaire"
              />
            </div>
          )}
        </div>
      )}
      <div
        className={styles.picture}
        style={{
          backgroundImage: `url("${
            process.env.REACT_APP_IMAGE_PATH
          }/350x240/${defaultPictureName(annonce.type, annonce.nature)}")`
        }}
      >
        {annonce.photo && (
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}/350x240/${annonce.photo}`}
            alt=""
          />
        )}
      </div>
      <span ref={containerRef}></span>
      <div className={styles.description}>
        <h3 className={styles.title}>{annonce.titre}</h3>
        <div className={styles.details}>
          <div
            className={styles.price}
            dangerouslySetInnerHTML={{ __html: price_content }}
          ></div>
          <div className={styles.surface}>{surface_content}</div>
        </div>
        {((statusTags === 'loading' && annonce.nature !== 'FON') ||
          (statusTags === 'success' && dataTags.length > 0 && showTags)) && (
          <div className={styles.tags}>
            {statusTags === 'loading' && <Dots />}
            {statusTags === 'success' && dataTags.length > 0
              ? dataTags.slice(0, 3).map((tag, i) => (
                  <div className={styles.tag} key={i}>
                    {tag.tag}
                  </div>
                ))
              : null}
          </div>
        )}
        {((statusActivites === 'loading' && annonce.nature === 'FON') ||
          (statusActivites === 'success' &&
            dataActivites.length > 0 &&
            showTags)) && (
          <div className={styles.tags}>
            {statusActivites === 'loading' && <Dots />}
            {statusActivites === 'success' && dataActivites.length > 0
              ? dataActivites.slice(0, 3).map((ac, i) => (
                  <div className={styles.tag} key={i}>
                    {ac.libelle}
                  </div>
                ))
              : null}
          </div>
        )}

        {date_send && message != '' && (
          <div className={styles.msg}>{message}</div>
        )}
      </div>
    </>
  );
};

export const AnnonceCardSkeleton = () => {
  return (
    <div className={styles.skeleton}>
      <Skeleton height="270px" width="270px" />
      <Skeleton height="1rem" width="190px" borderRadius="5px" />
      <Skeleton height="1rem" width="150px" borderRadius="5px" />
    </div>
  );
};

export default AnnonceCard;
