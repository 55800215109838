import * as yup from 'yup';

export const emptyNumber = (
  typeErrorMessage = 'Merci de selectionner un nombre valide'
) => {
  return yup
    .number()
    .transform(function (value, originalValue) {
      if (this.isType(value)) return value;
      if (!originalValue || !originalValue.trim()) {
        return null;
      }
      // we return the invalid original value
      return originalValue;
    })
    .nullable(true)
    .typeError(typeErrorMessage);
};
