import { createContext, useEffect, useState } from 'react';

export const ProgressContext = createContext();

const ProgressContextProvider = ({ children }) => {
  const [isProfileFilled, setIsProfileFilled] = useState(false);
  const [isProjectsFilled, setIsProjectsFilled] = useState(false);
  const [isAlertsFilled, setIsAlertsFilled] = useState(false);
  const [isRequestsFilled, setIsRequestsFilled] = useState(false);
  const [isFavoritesFilled, setIsFavoritesFilled] = useState(false);
  const [skipSettings, setSkipSettings] = useState(false);
  const [percentageFilled, setPercentageFilled] = useState(25);

  useEffect(() => {
    setPercentageFilled(25);
    isProfileFilled && setPercentageFilled((prev) => prev + 25);
    isProjectsFilled && setPercentageFilled((prev) => prev + 25);
    isAlertsFilled && setPercentageFilled((prev) => prev + 25);
  }, [isAlertsFilled, isProfileFilled, isProjectsFilled]);

  return (
    <ProgressContext.Provider
      value={{
        isProfileFilled,
        setIsProfileFilled,
        isProjectsFilled,
        setIsProjectsFilled,
        isAlertsFilled,
        setIsAlertsFilled,
        isRequestsFilled,
        setIsRequestsFilled,
        isFavoritesFilled,
        setIsFavoritesFilled,
        skipSettings,
        setSkipSettings,
        percentageFilled
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export default ProgressContextProvider;
