import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';

import axios from 'axios';

import { clearCache } from 'global/util';
import { fetchSiteMenu, fetchSiteMobileMenu } from 'global/queries';
import { availablesMenus } from 'global/utilsMenu';

import { UserContext } from 'contexts/UserContext';
import { ContactContext } from 'contexts/ContactContext';

import useWidth from 'hooks/useWidth';

import styles from './global_menu_bar.module.scss';

import { ReactComponent as MenuBars } from 'assets/menu.svg';
import { BiLogOutCircle } from 'react-icons/bi';

const GlobalMenuBar = () => {
  const [megaMenu, setMegaMenu] = useState(null);
  //const [mobileMenu, setMobileMenu] = useState(null);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const width = useWidth();
  const [menus, setMenus] = useState([]);
  const queryClient = useQueryClient();
  const { data: dataMegaMenu, status: megaMenuStatus } = useQuery(
    'fetchSiteMenu',
    fetchSiteMenu,
    {
      enabled: megaMenu == null && width >= 1024, //false pour test le basic
      refetchOnWindowFocus: false
    }
  );

  const { user, setIsLogged } = useContext(UserContext);

  useEffect(() => {
    setMenus(availablesMenus(location.pathname, user));
  }, [user, location.pathname]);

  const { showFormContact, setShowFormContact, message, setMessage } =
    useContext(ContactContext);

  useEffect(
    (_) => {
      if (dataMegaMenu)
        setMegaMenu(dataMegaMenu.replace(/<nav.[^<>]*>/gi, '').slice(0, -6));
    },
    [dataMegaMenu]
  );

  useEffect(
    (_) => {
      if (showFormContact) setMobileMenuVisible(false);
    },
    [showFormContact]
  );

  const handleLogout = async () => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/logout`,
      {},
      { withCredentials: true }
    );
    setIsLogged(false);
    queryClient.removeQueries();
    clearCache();
  };

  return (
    <>
      <link
        rel="stylesheet"
        href={`${process.env.REACT_APP_SITE_URL}/css/stylesheets/header.css`}
      />
      <link
        rel="stylesheet"
        href={`${process.env.REACT_APP_SITE_URL}/css/stylesheets/footer2.css`}
      />
      <header className={styles.wrapper}>
        <a
          href={`${process.env.REACT_APP_SITE_URL}`}
          className="logo"
          title="Geolocaux - Location et vente d'immobilier pro"
        >
          <img
            src={`${process.env.REACT_APP_SITE_URL}/images/header/geolocaux-extend-white.svg`}
            width="400"
            alt="Geolocaux - Location et vente d'immobilier pro"
          />
        </a>
        {width >= 1024 && (
          <>
            {megaMenu ? (
              <nav
                className="no-md"
                dangerouslySetInnerHTML={{
                  __html: megaMenu
                }}
              ></nav>
            ) : (
              <nav className="no-md">
                <ul>
                  <li className="has_sub">Louer</li>
                  <li className="has_sub">Acheter</li>

                  <li className="has_sub services">
                    <a
                      title="Nos services"
                      href={`${process.env.REACT_APP_SITE_URL}/services/professionnel/</li>`}
                    >
                      Services pro
                    </a>
                  </li>
                  <li className="agencies">
                    <a
                      href={`${process.env.REACT_APP_SITE_URL}/agences-immobilier-entreprise/`}
                      title="Trouver une agence"
                    >
                      Trouver une agence
                    </a>
                  </li>
                  <li>
                    <a href={`${process.env.REACT_APP_SITE_URL}/blog/`}>Blog</a>
                  </li>
                </ul>
              </nav>
            )}
          </>
        )}

        <div className="right">
          {width >= 1024 ? (
            <>
              {/* <span onclick="javascipt:myTools.CallObjectif('CLIC', 'HEADER', 'PHONE');" data-rel="nofollow" data-l="<%=ToolsData.Base64Encode("tel:"+Telephone.Replace(" ","")) %>" class="tel lk"><span class="call_icon"></span><span id="tel"><%=Telephone %></span></span> */}
              <a
                className="depot"
                href={`${process.env.REACT_APP_SITE_URL}/services/professionnel/diffusion-annonces.html`}
                title="Publiez votre annonce sur la plateforme Geolocaux.com"
              >
                Publication d'annonces
              </a>
              <ul className="no-xs">
                <li className="accmpt no-md">
                  <NavLink className="gl-user" to="/accueil"></NavLink>
                  <span className="gl-angle-down"></span>
                  <ul>
                    {menus.map((menu, key) => (
                      <li key={key}>
                        <NavLink
                          className={(navData) =>
                            `${styles.linkWrapper}${
                              menu.isActive() ? ` ${styles.selected}` : ''
                            }`
                          }
                          to={menu.to}
                        >
                          {menu.global}
                        </NavLink>
                      </li>
                    ))}
                    <li className={'logout ' + styles.liLogout}>
                      <a
                        onClick={() => handleLogout()}
                        title="Déconnexion"
                        className={styles.logout}
                      >
                        <BiLogOutCircle /> Déconnexion
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </>
          ) : (
            <MenuBars
              className={styles.menuBars}
              onClick={(_) => {
                setMobileMenuVisible(true);
                if (showFormContact) setShowFormContact(false);
              }}
            />
          )}
        </div>

        {mobileMenuVisible && (
          <div id="MMenu">
            <div id="headMMenu">
              <img
                src={`${process.env.REACT_APP_SITE_URL}/images/header/geolocaux-extend-white.svg`}
              />
              <span
                className="close"
                onClick={(_) => {
                  setMobileMenuVisible(false);
                }}
              ></span>
            </div>
            <div id="topMMenu">
              <NavLink
                className="gl-user"
                to="/accueil"
                onClick={(_) => {
                  setMobileMenuVisible(false);
                }}
              >
                <span>Compte</span>
              </NavLink>
              <span
                className="gl-envelope"
                onClick={(_) => {
                  setShowFormContact(true);
                }}
              >
                <span className="contact">Contact</span>
              </span>
            </div>
            <nav id="menu">
              <ul id="panel-menu">
                <li>
                  <a
                    href={`${process.env.REACT_APP_SITE_URL}/`}
                    title="Geolocaux, la première plateforme dédiée à l'immobilier d'entreprise"
                  >
                    Accueil
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_SITE_URL}/qui-sommes-nous.html`}
                    title="Une équipe de profesionnels de l'immobilier"
                  >
                    Qui-sommes-nous ?
                  </a>
                </li>
                <li>
                  <span>Annonces en location</span>
                  <ul>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_SITE_URL}/location/bureau/`}
                        title="Location de bureau"
                      >
                        Location bureau
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_SITE_URL}/coworking/`}
                        title="Location d'espace de bureaux flexibles / coworking"
                      >
                        Coworking & bureaux flexibles
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_SITE_URL}/location/local-commercial/`}
                        title="Location de local commercial"
                      >
                        Location local commercial
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_SITE_URL}/location/entrepot/`}
                        title="Location d'entrepôt"
                      >
                        Location entrepôt
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <span>Annonces en vente</span>
                  <ul>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_SITE_URL}/vente/bureau/`}
                      >
                        Vente de bureau
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_SITE_URL}/vente/local-commercial/`}
                      >
                        Vente de local commercial
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_SITE_URL}/vente/fonds-de-commerce/`}
                      >
                        Vente de fonds de commerce
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_SITE_URL}/vente/entrepot/`}
                      >
                        Vente d'entrepôt
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_SITE_URL}/vente/terrain/`}
                      >
                        Vente de terrain
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_SITE_URL}/services/professionnel/`}
                    title="Tous les services pour les professionnels de l'immobilier d'entreprise"
                  >
                    Tous nos services
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_SITE_URL}/location-bureaux-paris/prix-moyen/ligne-transport`}
                    title="Loyer moyen des bureaux à Paris par ligne de transport"
                  >
                    Prix et loyers
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_SITE_URL}/agences-immobillier-entreprise/`}
                    title="Trouver un professionnel de l'immobilier"
                  >
                    Trouver une agence
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${process.env.REACT_APP_SITE_URL}/blog/`}
                    title="Les dernières actualités de l'immobilier d'entreprise"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </header>
    </>
  );
};

export default GlobalMenuBar;
