import { useContext, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import axios from 'axios';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import Button from 'components/Button/Button';

import logo from 'assets/logo-geolocaux.svg';

import { clearCache } from 'global/util';
import { ProgressContext } from 'contexts/ProgressContext';

import styles from './login.module.scss';
import formStyles from 'components/Form/form.module.scss';

const schema = yup.object().shape({
  email: yup.string().required('Merci de renseigner votre email'),
  pwd: yup.string().required('Merci de renseigner votre mot de passe')
});

const Login = ({ fetchUser }) => {
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState([]);
  const [isForgotten, setIsForgotten] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const {
    setIsAlertsFilled,
    setIsFavoritesFilled,
    setIsRequestsFilled,
    setIsProjectsFilled,
    setIsProfileFilled
  } = useContext(ProgressContext);

  const onSubmit = async (data) => {
    if (!isForgotten) {
      const fetch = async () => {
        const req = await axios
          .post(
            `${process.env.REACT_APP_API_URL}/login`,
            { ...data, email: data.email.trim() },
            { withCredentials: true }
          )
          .catch((err) => {
            if (err.response.data === 'BAD_LOGIN')
              toast.error('Mot de passe ou identifiant incorrect');
            else if (err.response.data === 'NOT_FOUND')
              toast.error("Aucun compte n'est associé à cet identifiant");
            else {
              toast.error('Une erreur est survenue...');
            }
          });
        req && fetchUser(req.data, req.status);
      };
      fetch();
      navigate('/accueil');
    } else {
      const res = await axios
        .post(
          `${process.env.REACT_APP_API_URL}/user/forgot_password`,
          {
            mail: data.email.trim()
          },
          {
            withCredentials: true
          }
        )
        .catch((err) => {
          if (err.response.status === 400) {
            toast.error('Email introuvable... 😬');
          } else {
            toast.error('Une erreur est survenue 😬');
          }
          return;
        });
      res?.status === 200 && toast.success('Message envoyé! 🚀');
    }
  };

  useEffect(() => {
    setErrorMessages(Object.values(errors));
  }, [errors]);

  useEffect(() => {
    errorMessages.length > 0 && toast.error(errorMessages[0].message);
  }, [errorMessages]);

  useEffect(() => {
    clearCache();
    setIsAlertsFilled(false);
    setIsFavoritesFilled(false);
    setIsRequestsFilled(false);
    setIsProjectsFilled(false);
    setIsProfileFilled(false);
  }, []);

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.loginContainer}>
        <a href={process.env.REACT_APP_SITE_URL} className={styles.loginLogo}>
          <img src={logo} alt={process.env.REACT_APP_BRAND} />
          <span>Compte utilisateur</span>
        </a>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.slideUp}
          data-testid="form"
        >
          <div
            className={`${styles.formDiv} ${errors.email && formStyles.error}`}
          >
            <label htmlFor="email">Identifiant</label>
            <input
              id="email"
              placeholder="Votre adresse email"
              autoComplete="email"
              defaultValue=""
              {...register('email')}
            />
          </div>
          <div
            className={`${styles.formDiv} ${errors.pwd && formStyles.error} ${
              isForgotten && styles.hidden
            }`}
          >
            <label htmlFor="pwd">Mot de passe</label>
            <input
              id="pwd"
              placeholder="Votre mot de passe"
              autoComplete="current-password"
              type="password"
              defaultValue=""
              {...register('pwd')}
            />
          </div>
          <p
            className={styles.forgottenPassword}
            onClick={() => setIsForgotten((prev) => !prev)}
          >
            {!isForgotten ? 'Mot de passe oublié ? ' : 'Revenir au login'}
          </p>
          <Button>
            <p>{isForgotten ? 'Recevoir mon mot de passe' : 'Se connecter'}</p>
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
