import styles from './progressSteps.module.scss';

const ProgressSteps = ({ steps, current, className, setActiveStep }) => {
  return (
    <ul className={styles.steps + ' ' + (className || '')}>
      {steps.map(function (step, index) {
        return (
          <li
            key={index}
            className={`${index == current ? styles.selected : ''} 
              ${index < current ? styles.valid : ''}
              ${index <= current && styles.isClickable}
              ${styles.step}
              `}
            data-num={index + 1}
            onClick={() => index <= current && setActiveStep(index)}
          >
            <span>{step}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default ProgressSteps;
