import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import styles from './form.module.scss';

export const InputsLine = ({ children, className }) => {
  return (
    <div
      className={
        styles.inputsLine + ((className || '') != '' ? ' ' + className : '')
      }
    >
      {children}
    </div>
  );
};

export const InputsLike = ({ children, className }) => {
  return (
    <div
      className={
        styles.inputsLike + ((className || '') != '' ? ' ' + className : '')
      }
    >
      {children}
    </div>
  );
};

export const FormInput = ({
  label,
  name,
  id,
  type = 'text',
  defaultValue,
  className,
  register,
  placeholder,
  autoComplete,
  disabled,
  insetLabel = false,
  watch,
  onKeyUp,
  onChange
}) => {
  const searchInput = useRef(defaultValue);
  // const [focused, setFocused] = useState(false);
  // const onFocus = () => setFocused(true);
  // const onBlur = () => setFocused(false);
  const inputValue = watch ? watch(id || name) : null;
  const onWheel =
    type == 'number'
      ? (e) => {
          e.target.blur();
        }
      : null;
  const onKeyDown =
    type == 'number'
      ? (e) => {
          if (
            e.key === 'e' ||
            e.key == 38 /*up*/ ||
            e.key == 40 /*down*/ ||
            e.key == 'ArrowUp' /*up*/ ||
            e.key == 'ArrowDown' /*down*/
          )
            e.preventDefault();
        }
      : null;

  return (
    <div
      className={
        `${styles.input} ${
          watch && insetLabel && label ? styles.inputInsetLabel : ''
        } ${inputValue && inputValue.trim() !== '' ? styles.focused : ''}` +
        ((className || '') != '' ? ' ' + className : '')
      }
    >
      {label && <label htmlFor={id || name}>{label}</label>}
      <input
        ref={searchInput}
        placeholder={
          !(watch && insetLabel && label) && placeholder ? placeholder : null
        }
        type={type}
        id={id || name}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        disabled={disabled}
        onKeyUp={onKeyUp}
        onChange={onChange}
        onWheel={onWheel}
        onKeyDown={onKeyDown}
        // onFocus={onFocus}
        // onBlur={onBlur}
        {...register(name || id)}
      />
    </div>
  );
};

export const FormTextarea = ({
  label,
  name,
  id,
  defaultValue,
  className,
  register,
  placeholder
}) => {
  return (
    <div
      className={
        `${styles.input} ${styles.textarea}` +
        ((className || '') != '' ? ' ' + className : '')
      }
    >
      {label && <label htmlFor={id || name}>{label}</label>}
      <textarea
        id={id || name}
        {...register(name || id)}
        defaultValue={defaultValue}
        placeholder={placeholder}
      ></textarea>
    </div>
  );
};

export const FormRadio = ({
  label,
  name,
  id,
  className,
  value,
  checked = false,
  register,
  disabled
}) => {
  return (
    <div
      className={
        `${styles.input} ${styles.radio}` +
        ((className || '') != '' ? ' ' + className : '')
      }
    >
      <input
        type="radio"
        id={id || name}
        value={value}
        defaultChecked={checked}
        disabled={disabled}
        {...register(name || id)}
      />
      {label && <label htmlFor={id || name}>{label}</label>}
    </div>
  );
};

export const RadioContainer = ({ children, className }) => {
  return (
    <div
      className={
        styles.radioContainer + ((className || '') != '' ? ' ' + className : '')
      }
    >
      {children}
    </div>
  );
};

export const FormSelect = ({
  defaultValue,
  className,
  options,
  label,
  id,
  field,
  placeholder,
  isClearable,
  onChange,
  isMulti,
  register
}) => {
  return (
    <div
      className={
        `${styles.input}` + ((className || '') != '' ? ' ' + className : '')
      }
    >
      {label && <label htmlFor={id}>{label}</label>}
      {onChange && (
        <Select
          {...field}
          options={options}
          className={styles.reactSelect}
          isSearchable={false}
          defaultValue={defaultValue}
          placeholder={placeholder}
          isClearable={isClearable}
          onChange={onChange}
          isMulti={isMulti}
          register={register}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#def3ff',
              primary: '#17243f'
            }
          })}
        />
      )}
      {!onChange && (
        <Select
          {...field}
          options={options}
          className={styles.reactSelect}
          isSearchable={false}
          defaultValue={defaultValue}
          placeholder={placeholder}
          isClearable={isClearable}
          isMulti={isMulti}
          register={register}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#def3ff',
              primary: '#17243f'
            }
          })}
        />
      )}
    </div>
  );
};

export const FormAutoComplete = ({
  label,
  className,
  id,
  url,
  defaultValue,
  onChange,
  field,
  placeholder
}) => {
  const loadOptions = async (searchInput, callback) => {
    const res = await axios.get(`${url}?query=${searchInput}`);
    callback(
      res.data.map((city) => ({ label: city.name, value: city.place_id }))
    );
  };

  return (
    <div
      className={
        `${styles.input}` + ((className || '') != '' ? ' ' + className : '')
      }
    >
      {label && <label htmlFor={id}>{label}</label>}
      <AsyncSelect
        {...field}
        isMulti
        cacheOptions
        loadOptions={loadOptions}
        className={styles.reactSelectAutoComplete}
        value={defaultValue}
        onChange={onChange}
        noOptionsMessage={() => 'Aucun lieu trouvé ...'}
        placeholder={placeholder != null ? placeholder : 'Select...'}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#def3ff',
            primary: '#17243f'
          }
        })}
      />
    </div>
  );
};

export const FormAutoCompleteLocation = ({
  label,
  className,
  id,
  url = `${process.env.REACT_APP_API_URL}/locality/search`,
  defaultValue,
  query,
  onChange,
  field,
  isAsync = true,
  setValue,
  name,
  types = [
    'DOMTOM',
    'REGION',
    'DEPARTEMENT',
    'VILLE',
    'ARRONDISSEMENT',
    'QUARTIER',
    'STATION'
  ],
  ...rest
}) => {
  const loadOptions =
    isAsync &&
    (async (searchInput, callback) => {
      const res = await axios.get(`${url}?query=${searchInput}`);
      callback(
        orderOptions(res.data, types)
        //res.data.map((place) => ({ label: place.name, value: place.place_id }))
      );
    });
  const [cities, setCities] = useState([]);
  const [isInit, setIsInit] = useState(false);
  const [formatDefaultValue, setFormatDefaultValue] = useState('');
  const [prevQuery, setPrevQuery] = useState(null);

  const orderOptions = (options, [...filters]) => {
    const orderedArr = options
      .filter((opt) => filters.includes(opt.type_autocomplete))
      .map(
        (opt) => (opt = { ...opt, order: types.indexOf(opt.type_autocomplete) })
      );
    return orderedArr
      .reduce((r, opt) => {
        r[opt.order] = [...(r[opt.order] || []), opt];
        return r;
      }, [])
      .map((ordOpt, i) => ({
        label: types[i],
        options: ordOpt.map((place) => ({
          value: place.place_id,
          label: place.name,
          type: place.type_autocomplete
        }))
      }))
      .filter((opt) => opt);
  };

  const handleOnChange = (ctnt, edit) => {
    let _tmp = {
      target: { name: `${name}`, value: ctnt }
    };
    setValue(name, ctnt);
    onChange && onChange(_tmp);
  };

  useEffect(() => {
    if (!isAsync) {
      if (query) {
        if (query.length < 2) {
          setCities([]);
        } else {
          const fetch = async () => {
            const res = await axios.get(url + query);
            setCities(orderOptions(res.data, types));
          };
          fetch();
        }
      }
      // else {
      //    setValue(name, '');
      // } Ne pas remettre, pose problème à l'initialisation.
    }
  }, [query]);

  useEffect(() => {
    if (!isAsync) {
      setPrevQuery(query);
      if (defaultValue && !isInit && cities.length) {
        setIsInit(true);
        setFormatDefaultValue(
          cities[0].options.filter(
            (item) => item.value === defaultValue.place_id
          )
        );
      } else if (cities.length && !!prevQuery && !!query) {
        setValue(name, cities[0]?.options[0]);
      }
    }
  }, [defaultValue, cities]);

  return (
    <div
      className={
        `${styles.input}` + ((className || '') != '' ? ' ' + className : '')
      }
    >
      {label && <label htmlFor={id}>{label}</label>}
      {isAsync ? (
        <AsyncSelect
          {...field}
          {...rest}
          cacheOptions
          loadOptions={loadOptions}
          className={styles.reactSelectAutoComplete}
          value={defaultValue}
          onChange={handleOnChange}
          name={name}
          noOptionsMessage={() => 'Aucun lieu trouvé ...'}
          // options={cities}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#def3ff',
              primary: '#17243f'
            }
          })}
        />
      ) : (
        <Select
          {...field}
          {...rest}
          cacheOptions
          // loadOptions={cities}
          className={styles.reactSelectAutoComplete}
          // value={defaultValue}
          defaultValue={formatDefaultValue}
          options={cities}
          isSearchable={false}
          name={name}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#def3ff',
              primary: '#17243f'
            }
          })}
        />
      )}
    </div>
  );
};
export const FormCheckbox = ({
  children,
  className,
  register,
  id,
  name,
  defaultChecked,
  ...props
}) => {
  return (
    <div
      className={
        `${styles.input}` + ((className || '') != '' ? ' ' + className : '')
      }
      {...props}
    >
      <label>
        <input
          type="checkbox"
          {...register(name || id)}
          defaultChecked={defaultChecked}
        />
        <span>{children}</span>
      </label>
    </div>
  );
};
