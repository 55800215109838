import styles from './button.module.scss';

const Button = ({ children, className, ...props }) => {
  let classes = [styles.button];
  if (Array.isArray(className)) {
    if (className?.includes('blueBg')) classes.push(styles.blueBg);
    if (className?.includes('back')) classes.push(styles.back);
    if (className?.length) classes = classes.concat(className);
  } else if (typeof className === 'string') {
    classes = classes.concat(className.split(' '));
  }
  return (
    <button className={`${classes.join(' ')}`} {...props}>
      {children}
    </button>
  );
};

export default Button;
