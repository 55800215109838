import React, { Suspense, useContext, useEffect, useState, lazy } from 'react';
import {
  Navigate,
  Route,
  Routes,
  /*useNavigate,*/
  useLocation,
  useSearchParams
} from 'react-router-dom';
import { useQuery } from 'react-query';
import { UserContext } from '../contexts/UserContext';

import axios from 'axios';
import { ProgressContext } from 'contexts/ProgressContext';

import Home2 from 'pages/Home2/Home';
import Login from './Login/Login';

import { fetchUserData, autoLog } from 'global/queries';

import NavBar from 'components/NavBar/NavBar';
import GlobalMenuBar from 'components/NavBar/GlobalMenuBar';
import Footer from '../components/Footer/Footer';
import Profile from 'pages/Profile2/Profile';
import ContactIcons from 'components/ContactIcons';
import Loader from 'components/Loader/Loader';
import ContactForm from 'components/Contact/ContactForm';
import {
  urlServiceWorker,
  enabledServiceWorker
} from 'serviceWorkerRegistration';
import { ContactContext } from 'contexts/ContactContext';

import styles from './router.module.scss';
import { toast } from 'react-toastify';

const Router = () => {
  const {
    setIsAlertsFilled,
    setIsFavoritesFilled,
    setIsRequestsFilled,
    setIsProjectsFilled,
    setIsProfileFilled,
    setSeveralProject,
    setFirstProjectUri
  } = useContext(ProgressContext);
  const {
    user,
    setUser,
    isLogged,
    setIsLogged,
    setIsPro,
    setIsBroker,
    setHasPartenaire,
    setEnabledTransaction,
    setHasEtude,
    setHasChildren,
    setHasChildrenPartenaire,
    setCanAddAnnonce,
    setLastDisabledAbo,
    /*canEditAnnonce,*/
    setCanEditAnnonce,
    refetchUser,
    setRefetchUser,
    setContactTel,
    setContactPicture,
    setContactName,
    setContactServiceClient,
    setStripeBilling,
    setCanSubscribeAbo
  } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { showFormContact, setShowFormContact, setMessage } =
    useContext(ContactContext);
  const [endpoint, setEndpoint] = useState(null);
  const [p256dh, setP256dh] = useState(null);
  const [auth, setAuth] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [routes, setRoutes] = useState([]);
  //const [isFetching, setIsFetching] = useState(false);
  const [isAutoLog, setIsAutoLog] = useState(
    !!searchParams.get('k') &&
      !!searchParams.get('u') &&
      !isNaN(searchParams.get('u'))
  ); // && !!searchParams.get('tu') !required --> team user
  //const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (showFormContact) {
      setShowFormContact(false);
      setMessage('');
    }
  }, [location]);

  const fetchUser = async (usr, errno) => {
    if (usr != null) {
      let _routes = [
        {
          path: '/accueil',
          component: Home2
        },
        {
          path: '/profil',
          component: Profile
        }
      ];

      if (!usr.is_pro) {
        _routes.push({
          path: '/projets',
          component: React.lazy(() => import('./Project/List'))
        });
        _routes.push({
          path: '/projets/:id',
          component: React.lazy(() => import('./Project/Detail'))
        });
        _routes.push({
          path: '/projets/:id/settings',
          component: React.lazy(() => import('./Project/Form'))
        });
        if (setIsProfileFilled) {
          _routes.push({
            path: '/nouveau-projet',
            component: React.lazy(() => import('./Project/Form'))
          });
        }
        _routes.push({
          path: '/demandes',
          component: React.lazy(() => import('./Lead/List'))
        });
        _routes.push({
          path: '/favoris',
          component: React.lazy(() => import('./Favorite/List'))
        });
      }
      if (!usr.is_pro || usr.is_broker) {
        _routes.push({
          path: '/alertes',
          component: React.lazy(() => import('./Alert/List'))
        });
        _routes.push({
          path: '/alertes/:id/settings',
          component: React.lazy(() => import('./Alert/Form'))
        });
        _routes.push({
          path: '/nouvelle-alerte',
          component: React.lazy(() => import('./Alert/Form'))
        });
      }
      if (usr.is_pro) {
        _routes.push({
          path: '/annonces',
          component: React.lazy(() => import('pages/Annonce/List'))
        });

        _routes.push({
          path: '/factures',
          component: React.lazy(() => import('pages/Annonce/Invoices'))
        });

        _routes.push({
          path: '/devis',
          component: React.lazy(() => import('pages/Annonce/Invoices'))
        });

        if (usr.edit_annonce) {
          _routes.push({
            path: '/annonces/nouvelle-annonce',
            component: React.lazy(() => import('./Annonce/Form'))
          });
          _routes.push({
            path: '/annonces/:id/edit',
            component: React.lazy(() => import('./Annonce/Form'))
          });
        }

        _routes.push({
          path: '/geointelligence',
          component: React.lazy(() => import('pages/Geointelligence/Home'))
        });
        if (usr.has_partenaire || usr.has_children_partenaire) {
          _routes.push({
            path: '/transactions',
            component: React.lazy(() => import('pages/Transactions/List'))
          });

          if (usr.transaction_enable) {
            _routes.push({
              path: '/transactions/conversion',
              component: React.lazy(() => import('pages/Transactions/Convert'))
            });
            _routes.push({
              path: '/transactions/nouvelle-transaction',
              component: React.lazy(() => import('pages/Transactions/Form'))
            });
            _routes.push({
              path: '/transactions/:id/edit',
              component: React.lazy(() => import('pages/Transactions/Form'))
            });
          }
        }
        if (!usr.is_broker) {
          _routes.push({
            path: '/stats',
            component: React.lazy(() => import('pages/Stats/Stats'))
          });
        } else {
          _routes.push({
            path: '/selections',
            component: React.lazy(() => import('./Favorite/List'))
          });
        }
      }
      if (usr.has_etude) {
        _routes.push({
          path: '/etudes',
          component: React.lazy(() => import('./Etude/List'))
        });
      }

      setIsAlertsFilled(!usr.is_pro && usr.filled_alerte);
      setIsFavoritesFilled(!usr.is_pro && usr.filled_favoris);
      setIsRequestsFilled(!usr.is_pro && usr.filled_demande_annonce);
      setIsProjectsFilled(!usr.is_pro && usr.filled_project);

      setIsProfileFilled(
        usr.nom &&
          usr.prenom &&
          usr.email &&
          usr.tel &&
          (usr.societe || usr.societe_in_creation)
      );

      setUser(usr);
      setIsPro(usr.is_pro);
      setIsBroker(usr.is_broker);
      setHasPartenaire(usr.has_partenaire);
      setEnabledTransaction(usr.transaction_enable);
      setHasEtude(usr.has_etude);
      setHasChildren(usr.has_children);
      setCanEditAnnonce(usr.edit_annonce);
      setCanAddAnnonce(
        usr.edit_annonce &&
          (usr.is_pro || usr.is_broker) &&
          !usr.from_flux &&
          usr.abonnement != null &&
          usr.abonnement.nb_annonces > 0 &&
          usr.abonnement.nb_annonces > usr.nb_annonce_active
      );

      setStripeBilling(
        usr.stripe_abonnement_actif /*||
          (!usr.stripe_abonnement_actif &&
            usr.edit_annonce &&
            (usr.is_pro || usr.is_broker) &&
            !usr.from_flux &&
            usr.nb_annonce == 0)*/ //supprimé suite au ticket #4309
      );
      setCanSubscribeAbo(usr.can_subscribe_stripe_abo);
      setLastDisabledAbo(usr.lastRemovedStripeAbonnement);

      if (usr.is_pro || usr.is_broker) {
        setContactTel(
          !!usr.commercial?.telephone
            ? usr.commercial.telephone
            : process.env.REACT_APP_TEL_PRO
        );

        setContactPicture(
          usr.commercial?.photo
            ? `${process.env.PUBLIC_URL}/images/commercial/${usr.commercial?.photo}`
            : process.env.PUBLIC_URL + '/images/consultant/default_pro.jpg'
        );

        setContactName(
          (user?.commercial?.prenom ?? '') != ''
            ? user?.commercial?.prenom
            : 'Quentin'
        );
      } else {
        setContactTel(
          !!usr?.consultant?.tel
            ? usr.consultant.tel
            : process.env.REACT_APP_TEL_CONTACT
        );
        setContactPicture(
          !!usr?.consultant?.photo
            ? `${process.env.PUBLIC_URL}/images/consultant/${usr.consultant?.photo}`
            : process.env.PUBLIC_URL + '/images/consultant/default.jpg'
        );
        setContactName(
          (usr?.consultant?.name != '' ?? '') != ''
            ? usr?.consultant?.name
            : 'service client'
        );
        setContactServiceClient(!usr?.consultant?.name);
      }
      setRoutes(_routes);
      setIsLogged(true);
    } else {
      setIsLogged(false);
      setRoutes([]);
      setUser({});
      switch (errno) {
        case 'USER NOT FOUND':
          toast.error('Utilisateur non valide.');
          break;
      }
    }
    setRefetchUser(false);
    setIsLoading(false);
    setIsAutoLog(false);
  };

  const {
    data: dataUser,
    status: statusUser,
    isFetching: isFetchingUser,
    refetch: refetchUserQuery
  } = useQuery(['fetchUserData'], () => fetchUserData('fetchUserData'), {
    enabled: !isAutoLog,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    onSuccess: (data) => {
      if (data !== '') fetchUser(data);
      else fetchUser(null, 'EMPTY');
    },
    onError: (err) => {
      fetchUser(null, err?.response?.data);
    }
  });

  const { data: dataAutoLog, status: statusAutoLog } = useQuery(
    ['autoLog'],
    () =>
      autoLog('autoLog', {
        id: parseInt(searchParams.get('u')),
        key: searchParams.get('k')
      }),
    {
      enabled: isAutoLog,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      onSuccess: (data) => {
        if (data !== '') fetchUser(data);
        else fetchUser(null, 'EMPTY');
      },
      onError: (err) => {
        fetchUser(null, err?.response?.data);
        console.log(err.response.data);
      }
    }
  );

  //Avoids disconnection on refresh
  useEffect(() => {
    if (!isAutoLog) refetchUserQuery();
  }, []);

  useEffect(() => {
    if (refetchUser && !isFetchingUser && !isAutoLog) refetchUserQuery(); //to force refetch user params
  }, [refetchUser]);

  //Subscribe to SW on connection
  useEffect(() => {
    if (!isLogged) return;

    if (enabledServiceWorker && 'serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register(urlServiceWorker).then((reg) => {
          if (Notification?.permission === 'granted') {
            getSubscription(reg);
          } else if (Notification?.permission === 'denied') {
            console.log('notifications blocked');
          } else {
            requestNotificationAccess(reg);
          }
        });
      });
    }
    function requestNotificationAccess(reg) {
      Notification?.requestPermission(function (status) {
        if (status == 'granted') {
          getSubscription(reg);
        } else {
          console.log('no support');
        }
      });
    }

    function getSubscription(reg) {
      reg.pushManager?.getSubscription().then(function (sub) {
        if (sub === null) {
          reg.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey:
                'BAaow438bLmGg9LwpV7RoHBD0Ne38CTSYQLXtjzUCiIYggMVswHAY-i1gxehbWt2fudS0OnRbj9GmT0byauLLr0'
            })
            .then(function (sub) {
              console.log(sub);
            })
            .catch(function (e) {
              console.error('Unable to subscribe to push', e);
            });
        } else {
          fillSubscribeFields(sub);
        }
      });
    }

    function fillSubscribeFields(sub) {
      setEndpoint(sub.endpoint);
      setP256dh(arrayBufferToBase64(sub.getKey('p256dh')));
      setAuth(arrayBufferToBase64(sub.getKey('auth')));
    }

    function arrayBufferToBase64(buffer) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }
  }, [isLogged]);

  useEffect(() => {
    if (endpoint && p256dh && auth) {
      axios.post(
        `${process.env.REACT_APP_ACCOUNT_URL}/push/subscribe`,
        {
          endpoint,
          p256dh,
          auth
        },
        { withCredentials: true }
      );
    }
  }, [endpoint, p256dh, auth]);

  if (isLoading || isAutoLog) {
    return <Loader className={styles.loader} />;
  }

  return (
    <>
      {!isLogged ? (
        <Routes>
          <Route path="/" element={<Login fetchUser={fetchUser} />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <>
          <GlobalMenuBar />
          <NavBar />
          <Suspense fallback={<Loader className={styles.loader} />}>
            <Routes>
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    caseSensitive={false}
                    path={route.path}
                    name={route.name}
                    //render={(props) => <route.component {...props} />}
                    element={<route.component />}
                  />
                ) : null;
              })}
              <Route path="*" element={<Navigate to="/accueil" replace />} />
            </Routes>
          </Suspense>
          <ContactIcons />
          <ContactForm />
          <Footer />
        </>
      )}
    </>
  );
};

export default Router;
