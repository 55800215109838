import { useContext, useEffect, useState } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import moment from 'moment';

import CardTitle from 'components/Layout/CardTitle';

import { UserPasswordForm, UserForm } from './Profile';

import { UserContext } from 'contexts/UserContext';
import { ContactContext } from 'contexts/ContactContext';

import styles from './profile.module.scss';

import { ReactComponent as FactureSvg } from 'assets/facture.svg';
import { ReactComponent as DevisSvg } from 'assets/devis.svg';
import { ReactComponent as ContratSvg } from 'assets/contrat.svg';
import { ReactComponent as PublishSvg } from 'assets/add_circle.svg';

import {
  anyInvoices,
  anyDevis,
  fetchAboData,
  lastImportPaserelle,
  fetchQuota
} from 'global/queries';

export const AnnonceurSideActions = () => {
  const { isPro, user, stripeBilling, canSubscribeAbo, lastDisabledAbo } =
    useContext(UserContext);
  const { setShowFormContact } = useContext(ContactContext);
  const [nid, setNid] = useState('rt_qkActions');
  const navigate = useNavigate();

  const { data: anyInvoicesData, status: anyInvoicesStatus } = useQuery(
    'anyInvoices',
    anyInvoices,
    {
      enabled: isPro,
      refetchOnWindowFocus: false
    }
  );

  const { data: anyDevisData, status: anyDevisStatus } = useQuery(
    'anyDevis',
    anyDevis,
    {
      enabled: isPro && !(!user.from_flux && !!stripeBilling),
      refetchOnWindowFocus: false
    }
  );

  const { data: quotaData, status: quotaStatus } = useQuery(
    'fetchQuota',
    fetchQuota,
    { enabled: true, refetchOnWindowFocus: false }
  );

  return (
    <>
      {anyInvoicesStatus === 'success' && (
        <>
          <div
            onClick={() => anyInvoicesData === true && navigate('/factures')}
            data-tip={
              anyInvoicesData === true
                ? null
                : 'Aucune facture disponible pour le moment'
            }
            className={[
              styles.access,
              styles.facture,
              anyInvoicesData === true ? styles.enabled : styles.disabled
            ].join(' ')}
          >
            <span>Factures</span>
            <FactureSvg />
          </div>
          <ReactTooltip className={styles.tooltipText} />
        </>
      )}
      {anyDevisStatus === 'success' &&
        !(
          !stripeBilling &&
          canSubscribeAbo &&
          !!lastDisabledAbo &&
          lastDisabledAbo != null
        ) && (
          <>
            <div
              onClick={() => anyDevisData === true && navigate('/devis')}
              data-tip={
                anyDevisData === true
                  ? null
                  : 'Aucun devis disponible pour le moment'
              }
              className={[
                styles.access,
                styles.devis,
                anyDevisData === true ? styles.enabled : styles.disabled
              ].join(' ')}
            >
              <span>Devis</span>
              <DevisSvg />
            </div>
            <ReactTooltip className={styles.tooltipText} />
          </>
        )}

      {user &&
      ((!user.from_flux && !!stripeBilling) ||
        (!stripeBilling &&
          canSubscribeAbo &&
          !!lastDisabledAbo &&
          lastDisabledAbo != null)) ? (
        <a
          href={`${process.env.REACT_APP_SITE_URL}/abonnement.html`}
          target="_blank"
          rel="noreferrer"
          className={[styles.access, styles.contrat].join(' ')}
        >
          <span>Gérer mon abonnement</span>
          <ContratSvg />
        </a>
      ) : (
        <>
          {true == true && (
            <>
              <div
                onClick={() =>
                  !!user.contrat &&
                  window.open(process.env.REACT_APP_ACCOUNT_URL + user.contrat)
                }
                className={[
                  styles.access,
                  styles.contrat,
                  !!user.contrat ? '' : styles.disabled
                ].join(' ')}
                data-tip={
                  !!user.contrat
                    ? null
                    : `Le contrat n'est pas disponible pour le moment`
                }
              >
                <span>Contrat</span>
                <ContratSvg />
              </div>
              <ReactTooltip className={styles.tooltipText} />
            </>
          )}
        </>
      )}
      {quotaData && !(quotaData.unlimited == true) && !!quotaData.max && (
        <>
          <span
            className={`${[styles.access, styles.publish].join(' ')}`}
            onClick={() => setShowFormContact((prev) => !prev)}
          >
            <span>Publier plus d'annonces</span>
            <PublishSvg />
          </span>
          <ReactTooltip className={styles.tooltipText} />
        </>
      )}
    </>
  );
};

const Annonceur = () => {
  const { isBroker, user, stripeBilling, canSubscribeAbo, lastDisabledAbo } =
    useContext(UserContext);

  const { data: quotaData, status: quotaStatus } = useQuery(
    'fetchQuota',
    fetchQuota,
    { enabled: true, refetchOnWindowFocus: false }
  );
  const { data: lastImportPaserelleData, status: lastImportPaserelleStatus } =
    useQuery('lastImportPaserelle', lastImportPaserelle, {
      enabled: !!user.from_flux,
      refetchOnWindowFocus: false
    });

  const { data: dataAbo, status: statusAbo } = useQuery(
    'fetchAboData',
    fetchAboData,
    {
      enabled: !!stripeBilling,
      refetchOnWindowFocus: false
    }
  );

  const [fetchAbonnementSection, setFetchAbonnementSection] = useState(false);

  useEffect(
    (_) => {
      setFetchAbonnementSection(
        !isBroker &&
          ((quotaData && (quotaData.unlimited || !!quotaData?.max)) ||
            (!!user.from_flux &&
              lastImportPaserelleData &&
              lastImportPaserelleStatus !== 'loading' &&
              lastImportPaserelleData.date_end) ||
            (quotaData && !!quotaData.start_abo) ||
            (user.edit_annonce && quotaData) ||
            (!user.edit_annonce &&
              canSubscribeAbo &&
              !!lastDisabledAbo &&
              lastDisabledAbo != null &&
              (lastDisabledAbo.reason == 'SUBSCRIPTION_PAST_DUE' ||
                lastDisabledAbo.date_cancel != null)))
      );
    },
    [
      isBroker,
      quotaData,
      user,
      lastImportPaserelleData,
      canSubscribeAbo,
      lastDisabledAbo
    ]
  );

  return (
    <>
      {fetchAbonnementSection && (
        <div className={styles.tuile + ' ' + styles.abonnement}>
          <CardTitle title={`Abonnement`} className={styles.cardTitle} />
          <ul className={styles.elts}>
            {quotaData && (quotaData.unlimited || !!quotaData?.max) && (
              <li>
                <span>
                  {quotaData.unlimited ? (
                    "Vous pouvez diffuser l'ensemble de vos offres disponibles"
                  ) : (
                    <>
                      Forfait {quotaData?.max} annonce
                      {quotaData?.max > 1 ? 's' : ''}
                    </>
                  )}
                </span>
                {!quotaData.unlimited && !!quotaData?.max && (
                  <span>
                    {quotaData?.enable} / {quotaData?.max} utilisée
                    {quotaData?.enable > 1 ? 's' : ''}
                  </span>
                )}
              </li>
            )}
            {!!user.from_flux &&
              lastImportPaserelleData &&
              lastImportPaserelleStatus !== 'loading' &&
              lastImportPaserelleData.date_end && (
                <li>
                  <span>Dernière mise à jour de votre flux</span>
                  <span>
                    {moment(lastImportPaserelleData.date_end).format(
                      'DD/MM/YYYY à HH[h]mm'
                    )}
                  </span>
                </li>
              )}
            {quotaData && !!quotaData.start_abo && (
              <li>
                <span>Début de partenariat</span>
                <span>
                  {new Date(quotaData.start_abo).toLocaleDateString('fr', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  })}
                </span>
              </li>
            )}
          </ul>
          {user.edit_annonce && quotaData && (
            <>
              {user &&
                statusAbo !== 'loading' &&
                !!dataAbo &&
                !!dataAbo.date_expire && (
                  <>
                    <div className={[styles.renew].join(' ')}>
                      {!!dataAbo.date_cancel ? (
                        <>
                          Fin de l'abonnement le{' '}
                          {new Date(dataAbo.date_expire).toLocaleDateString(
                            'fr',
                            {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric'
                            }
                          )}{' '}
                          à la suite de votre résiliation.{' '}
                          <span className={styles.warning_abo}>
                            Pour réactiver votre abonnement, cliquez sur le
                            bouton "Gérer mon abonnement" puis "Réactiver mon
                            abonnement
                          </span>
                        </>
                      ) : (
                        <>
                          Prochain renouvèlement prévu le{' '}
                          {new Date(dataAbo.date_expire).toLocaleDateString(
                            'fr',
                            {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric'
                            }
                          )}
                        </>
                      )}
                    </div>
                    {!dataAbo.date_cancel && (
                      <span className={[styles.warning_abo].join(' ')}>
                        La mise hors ligne de{' '}
                        {quotaData?.enable > 1
                          ? 'vos annonces '
                          : 'votre annonce '}
                        n'entrainera pas la résiliation de votre abonnement.
                        <br />
                        <br />
                        Pour cela, cliquez sur le bouton "Gérer mon abonnement"
                        puis "Résilier mon abonnement" sur la page dédiée.
                      </span>
                    )}
                  </>
                )}
            </>
          )}

          {!user.edit_annonce &&
            canSubscribeAbo &&
            !!lastDisabledAbo &&
            lastDisabledAbo != null &&
            (lastDisabledAbo.reason == 'SUBSCRIPTION_PAST_DUE' ||
              lastDisabledAbo.date_cancel != null) && (
              <>
                <span className={styles.renew}>
                  {lastDisabledAbo.reason == 'SUBSCRIPTION_PAST_DUE' ? (
                    <>
                      Votre abonnement n'a pas été renouvelé le{' '}
                      {moment(lastDisabledAbo.date_cancel).format('DD/MM/YYYY')}{' '}
                      en raison d'une erreur de paiement
                    </>
                  ) : (
                    <>
                      Votre abonnement est résilié depuis le{' '}
                      {moment(lastDisabledAbo.date_cancel).format('DD/MM/YYYY')}
                    </>
                  )}
                  , veuillez souscrire à un nouvel abonnement si vous souhaitez
                  remettre vos annonces en ligne.
                </span>
                <span className={styles.warning_abo}>
                  Pour réactiver votre abonnement, cliquez sur le bouton "Gérer
                  mon abonnement" puis "Réactiver mon abonnement"
                </span>
              </>
            )}
          {/* {
            <>
              {user &&
                !stripeBilling &&
                canSubscribeAbo &&
                !!lastDisabledAbo &&
                lastDisabledAbo != null && (
                  <a
                    href={`${process.env.REACT_APP_SITE_URL}/abonnement.html`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Souscrire à un nouvel abonnement
                  </a>
                )}
            </>
          } */}
        </div>
      )}
      <UserForm />
      <UserPasswordForm />
      {user.partenaire && (
        <div className={styles.tuile + ' ' + styles.avatar}>
          <CardTitle title={`Logo`} className={styles.cardTitle} />
          <div className={styles.pic_row}>
            <picture>
              <img
                draggable="false"
                src={
                  process.env.REACT_APP_SITE_URL +
                  '/photos/partenaires/logos/' +
                  user.partenaire.id +
                  '_on.jpg'
                }
              />
            </picture>
            <div className={styles.speach}>
              Votre logo est visible sur l'ensemble des vos annonces et{' '}
              <a
                href={`${process.env.REACT_APP_SITE_URL}${user.partenaire.url}`}
                target="_blank"
                rel="noreferrer"
              >
                votre page vitrine.
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Annonceur;
