import { ContactContext } from 'contexts/ContactContext';

import { useNavigate, useLocation } from 'react-router';
import { ReactComponent as Phone } from 'assets/phone.svg';
import { FiMail } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';

import buttonStyles from 'components/Button/button.module.scss';
import Button from './Button/Button';

import styles from 'components/Footer/footer.module.scss';
import { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import useScroll from 'hooks/useScroll';

const ContactIcons = () => {
  const visible = useScroll(true);
  const { setShowFormContact } = useContext(ContactContext);
  const location = useLocation();
  const {
    user,
    canEditAnnonce,
    canAddAnnonce,
    contactTel,
    enabledTransaction
  } = useContext(UserContext);

  const callLink = () => {
    document.location.href = `tel:${contactTel.replace(/\s/g, '')}`;
  };

  return (
    <>
      <div
        className={styles.iconsContainer + (visible ? '' : ' ' + styles.bottom)}
      >
        {location.pathname === '/annonces' && user && canAddAnnonce && (
          <BubbleButton
            urlPush="/annonces/nouvelle-annonce"
            label="Déposer une annonce"
            icon={<MdAdd />}
          />
        )}
        {/* {location.pathname === '/alertes' && (
          <BubbleButton
            urlPush="/nouvelle-alerte"
            label="Créer une nouvelle alerte"
            icon={<MdAdd />}
          />
        )} */}
        {/* {location.pathname === '/projets' && (
          <BubbleButton
            urlPush="/nouveau-projet"
            label="Créer un nouveau projet"
            icon={<MdAdd />}
          />
        )} */}
        {enabledTransaction && location.pathname === '/transactions' && (
          <BubbleButton
            urlPush="/transactions/nouvelle-transaction"
            label="Publier une transaction"
            icon={<MdAdd />}
          />
        )}

        <BubbleButton
          label="Contactez nous"
          onClick={() => setShowFormContact((prev) => !prev)}
          className={styles.formTrigger}
          icon={<FiMail />}
        />

        <BubbleButton
          label={contactTel}
          onClick={callLink}
          className={styles.formTrigger}
          icon={<Phone />}
        />
      </div>
    </>
  );
};

export default ContactIcons;

const BubbleButton = ({ urlPush, onClick, label, icon, className }) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() =>
        typeof onClick == 'function' ? onClick() : navigate(urlPush)
      }
      className={buttonStyles.createButton + ' ' + (className || '')}
    >
      <span className={buttonStyles.iconInitialText}>{icon}</span>
      <span className={buttonStyles.iconHoverText}>{label}</span>
    </Button>
  );
};

const BubbleLink = ({ url, label, icon, className, target }) => {
  return (
    <a
      href={url}
      title={label}
      target={target}
      className={buttonStyles.createButton + ' ' + (className || '')}
    >
      <span className={buttonStyles.iconInitialText}>{icon}</span>
      <span className={buttonStyles.iconHoverText}>{label}</span>
    </a>
  );
};
