import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { UserPasswordForm, UserForm } from './Profile';

import { UserContext } from 'contexts/UserContext';
import { ProgressContext } from 'contexts/ProgressContext';

import Button from 'components/Button/Button';

import styles from './profile.module.scss';

const Utilisateur = () => {
  const navigate = useNavigate();
  const { isPro, isBroker, user } = useContext(UserContext);

  const { isProfileFilled, isProjectsFilled, percentageFilled } =
    useContext(ProgressContext);

  return (
    <>
      {percentageFilled < 100 && !isPro && !isBroker && (
        <div className={[styles.profilBanner].join(' ')}>
          {!isProfileFilled ? (
            <strong>
              Votre profil est rempli à {percentageFilled}%, veuillez le
              compléter ci-dessous.
            </strong>
          ) : !isProjectsFilled ? (
            <>
              <strong>
                Votre profil est rempli à {percentageFilled}%, aidez nous à
                mieux vous connaitre en créant votre premier projet !
              </strong>
              <Button onClick={() => navigate('/nouveau-projet')}>
                Créer un projet
              </Button>
            </>
          ) : (
            <>
              <strong>
                Votre profil est rempli à {percentageFilled}%, soyez le premier
                a être alerté des opportunités en créant votre première alerte !
              </strong>
              <Button onClick={() => navigate('/nouvelle-alerte')}>
                Créer ma 1ère alerte
              </Button>
            </>
          )}
        </div>
      )}
      <UserForm />
      <UserPasswordForm />
    </>
  );
};

export default Utilisateur;
