import { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import styles from './navbar.module.scss';

import { UserContext } from 'contexts/UserContext';
import { availablesMenus } from 'global/utilsMenu';

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const [menus, setMenus] = useState([]);

  const { user } = useContext(UserContext);

  useEffect(() => {
    setMenus(availablesMenus(location.pathname, user));
  }, [user, location.pathname]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [open]);

  return (
    <div className={styles.wrapper}>
      <ul className={styles.navLinks}>
        {menus.map((menu, key) => (
          <NavLink
            key={key}
            className={(navData) =>
              `${styles.linkWrapper}${
                menu.isActive() ? ` ${styles.selected}` : ''
              }`
            }
            to={menu.to}
          >
            {menu.navbar}
          </NavLink>
        ))}
      </ul>
    </div>
  );
};

export default NavBar;
