import { ReactComponent as Dots } from 'assets/three-dots.svg';

import styles from './loader.module.scss';

const Loader = ({ text, className }) => {
  return (
    <div
      className={
        styles.loaderCard + ((className || '') != '' ? ` ${className}` : '')
      }
    >
      <Dots className="dots" />
      <h3>{text}</h3>
    </div>
  );
};

export default Loader;
