import { Link } from 'react-router-dom';

import styles from './cardTitle.module.scss';

const CardTitle = ({ title, description, className, back, descLink }) => {
  return (
    <div
      className={
        styles.container + ((className || '') != '' ? ` ${className}` : '')
      }
    >
      {back ? (
        <div className={styles.title_container}>
          <h2 className={styles.title}>{title}</h2>
          <Link to={back}>Retour</Link>
        </div>
      ) : (
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      <div className={styles.content}>
        {description && (
          <p
            dangerouslySetInnerHTML={{ __html: description }}
            className={styles.text}
          ></p>
        )}
        {descLink}
      </div>
    </div>
  );
};

export default CardTitle;
