import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

import Annonce from 'pages/Annonce/Card';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import styles from './swiper.module.scss';
import useWidth from 'hooks/useWidth';

SwiperCore.use([Navigation]);

const SwiperAnnonce = ({
  annonces,
  navigation,
  asLead = false,
  showTags = true,
  header
}) => {
  let modules = [];
  if (navigation) modules.push(Navigation);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);
  const [hasReachedBeginning, setHasReachedBeginning] = useState(true);
  const width = useWidth();
  const [hover, setHover] = useState(false);

  let params = {
    modules: modules,
    preloadImages: false,
    centeredSlides: width < 768 ? true : false,
    centerInsufficientSlides: false,
    watchOverflow: true,
    loop: false,
    slidesPerView: 'auto',
    resistanceRatio: 0,
    spaceBetween: 20
  };

  const handleSlideChange = () => {
    hasReachedBeginning && setHasReachedBeginning(false);
    hasReachedEnd && setHasReachedEnd(false);
  };

  return (
    <Swiper
      {...params}
      className={
        styles.swiper +
        (header ? ' ' + styles.hasHeader : '') +
        (hasReachedBeginning ? ' ' + styles.hasReachedBeginning : '') +
        (hasReachedEnd ? ' ' + styles.hasReachedEnd : '')
      }
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current
      }}
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationPrevRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
      onFromEdge={handleSlideChange}
      onReachEnd={() => setHasReachedEnd(true)}
      onReachBeginning={() => setHasReachedBeginning(true)}
    >
      {annonces &&
        annonces?.map((annonce, index) => (
          <SwiperSlide key={annonce.id} className={styles.slide}>
            <Annonce
              annonce={annonce}
              id={annonce.id}
              asLead={asLead}
              showTags={showTags}
              hover={hover}
              setHover={setHover}
            />
          </SwiperSlide>
        ))}
      {navigation && (
        <>
          <div
            ref={navigationPrevRef}
            className={`swiper-button-prev ${styles.prev}`}
          ></div>
          <div
            ref={navigationNextRef}
            className={`swiper-button-next ${styles.next}`}
          ></div>
        </>
      )}
    </Swiper>
  );
};
export default SwiperAnnonce;
