import { createContext, useState } from 'react';

export const ContactContext = createContext();

const ContactContextProvider = ({ children }) => {
  const [showFormContact, setShowFormContact] = useState(false);
  const [message, setMessage] = useState('');

  return (
    <ContactContext.Provider
      value={{
        showFormContact,
        setShowFormContact,
        message,
        setMessage
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export default ContactContextProvider;
