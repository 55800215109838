import { useEffect, useRef, useState } from 'react';

export const useElementOnScreen = (options) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    containerRef.current && observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [containerRef, options]);
  return [containerRef, isVisible];
};
