import axios from 'axios';
import { dateWithoutTimezone } from 'global/util';
import { toast } from 'react-toastify';

export const autoLog = async (_, params) => {
  const { id, key } = params;
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/autolog`,
    {
      id,
      key
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchUserData = async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
    withCredentials: true
  });
  return res.data;
};

export const fetchUserAlerts = async ({ pageParam = 1, queryKey }) => {
  const [_, { limit = 20 }] = queryKey;
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/alertes?page=${pageParam}&limit=${limit}&limit_annonce=3`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchLastAlerts = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/alertes?limit=2&limit_annonce=1`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchUserAlert = async (key, id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/alerte/${id}`,
    {
      withCredentials: true
    }
  );
  return res.data;
};

export const fetchLastUserProject = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/projets?limit=2`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchUserProjects = async ({ pageParam = 1, queryKey }) => {
  const [_, { limit = 20 }] = queryKey;
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/projets?page=${pageParam}&limit=${limit}&limit_annonce=3`,
    {
      withCredentials: true
    }
  );
  return res.data;
};

export const fetchUserProject = async (key, id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/projet/${id}`,
    {
      withCredentials: true
    }
  );
  return res.data;
};

export const notifyProjectConsultant = async (id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/projet/${id}/consultant/notify`,
    {
      withCredentials: true
    }
  );
  return res.data;
};

export const projectCompleted = async (id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/projet/${id}/completed`,
    {
      withCredentials: true
    }
  );
  return res.data;
};

export const createProjectAlert = async (id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/projet/${id}/alerte`,
    {
      withCredentials: true
    }
  );
  return res.data;
};

export const fetchProjetAnnonces = async (key, id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/projet/${id}/annonces`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchProjetDemandes = async (key, id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/projet/${id}/demandes`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchLeadAnnonces = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/demandes/annonces?limit=10`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchLeadProjects = async ({ pageParam = 1, queryKey }) => {
  const [_, { limit = 20 }] = queryKey;
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/demandes/projets?limit=${limit}&page=${pageParam}`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchUserLeads = async (
  key,
  start,
  end,
  page = 1,
  childrenSelected = []
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/leads?page=${page}`,
    {
      start: start ? dateWithoutTimezone(start) : null,
      end: end ? dateWithoutTimezone(end) : null,
      childrenSelected
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchUserLeadsQualifies = async (
  key,
  start,
  end,
  page = 1,
  childrenSelected = []
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/leads_qualifies?page=${page}`,
    {
      start: start ? dateWithoutTimezone(start) : null,
      end: end ? dateWithoutTimezone(end) : null,
      childrenSelected
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchUserShowTels = async (
  key,
  start,
  end,
  page = 1,
  childrenSelected = []
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/show_tels?page=${page}`,
    {
      start: start ? dateWithoutTimezone(start) : null,
      end: end ? dateWithoutTimezone(end) : null,
      childrenSelected
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchUserCalls = async (
  key,
  start,
  end,
  page = 1,
  childrenSelected = []
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/calls?page=${page}`,
    {
      start: start ? dateWithoutTimezone(start) : null,
      end: end ? dateWithoutTimezone(end) : null,
      childrenSelected
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchUserViews = async (
  key,
  start,
  end,
  page = 1,
  childrenSelected = [],
  reference
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/views?page=${page}`,
    {
      start: start ? dateWithoutTimezone(start) : null,
      end: end ? dateWithoutTimezone(end) : null,
      childrenSelected,
      reference
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchFavorites = async ({ pageParam = 1, queryKey }) => {
  const [_, { limit = 20 }] = queryKey || [];
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/favoris?page=${pageParam}&limit=${limit}&limit_annnonce=3`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchTags = async (key, id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/annonce/tag/${id}`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchActivites = async (key, id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/annonce/activite/${id}`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchPartenaire = async (key, id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/partenaire/${id}`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchPartenairesListe = async (key, partners, limit) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/projet/partenaires?${partners
      .map((p) => 'ids=' + p)
      .join('&')}&limit=${limit}`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchAnnoncesPro = async ({ pageParam = 1, queryKey = [] }) => {
  const [_, { limit = 20, sort, queryParams = {}, childrenSelected = [] }] =
    queryKey;
  const {
    surface_min,
    surface_max,
    price_min,
    price_max,
    nature,
    type,
    localisations,
    reference,
    annonce_option = {
      light: true,
      arbo_localisation: false,
      zone_localisation: false,
      stats: true
    }
  } = queryParams;
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/annonces`,
    {
      sort,
      limit,
      surface_min,
      surface_max,
      price_min,
      price_max,
      nature,
      reference,
      type,
      localisations,
      page: pageParam,
      annonce_option,
      childrenSelected
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchUserAnnonce = async (key, id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/annonce/${id}`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchTopAnnonces = async (_, queryKey) => {
  const { limit, period, start, end } = queryKey;
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/annonces/top`,
    {
      limit: limit,
      duration: period,
      start: start ? dateWithoutTimezone(start) : null,
      end: end ? dateWithoutTimezone(end) : null
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchAnnoncesStats = async (
  key,
  start,
  end,
  period,
  childrenSelected = [],
  kpis = null
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/annonces/stats`,
    {
      start: start ? dateWithoutTimezone(start) : null,
      end: end ? dateWithoutTimezone(end) : null,
      period,
      childrenSelected,
      kpis
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchAnnonceIdStats = async (key, id, kpis = null) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/annonce/stats/${id}`,
    {
      kpis
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchRepartition = async (
  key,
  childrenSelected = [],
  startDate = null,
  endDate = null,
  kpi = null
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/annonces/repartition`,
    {
      start: startDate ? dateWithoutTimezone(startDate) : null,
      end: endDate ? dateWithoutTimezone(endDate) : null,
      childrenSelected,
      kpi
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchTransactions = async ({ pageParam = 1, queryKey = [] }) => {
  const [_, { limit = 20, sort = '', queryParams = {} }] = queryKey;
  const {
    surface_min,
    surface_max,
    price_min,
    price_max,
    nature,
    type,
    start,
    end,
    reference
  } = queryParams;
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/transactions`,
    {
      sort,
      limit,
      surface_min,
      surface_max,
      price_min,
      price_max,
      nature,
      type,
      page: pageParam,
      start: start ? dateWithoutTimezone(start) : null,
      end: end ? dateWithoutTimezone(end) : null,
      reference
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchTransaction = async (key, id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/transaction/${id}`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchTransactionAnnonce = async (key, id) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/transaction/annonce/`,
    {
      id
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchAnnoncesConvertibleTransaction = async (_, queryKey) => {
  const { limit = 20, reference = '' } = queryKey;
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/transaction/convertible`,
    {
      limit,
      reference
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchRemainningConvertibleTransaction = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/transaction/convertible/count`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchCitiesWithZipcode = async (key, zipcode) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/locality/search/cp?query=${zipcode}`,
    {
      withCredentials: true
    }
  );
  return res.data;
};

export const fetchDepartementList = async (key) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/locality/list/departement`,
    {
      withCredentials: true
    }
  );
  return res.data;
};

export const fetchStatsRecap = async (
  key,
  startDate = null,
  endDate = null,
  kpis = null,
  activesRelatedOnly = false,
  childrenSelected = []
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/annonces/stats/recap`,
    {
      start: startDate ? dateWithoutTimezone(startDate) : null,
      end: endDate ? dateWithoutTimezone(endDate) : null,
      kpis,
      activesRelatedOnly,
      childrenSelected
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchQuota = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/annonce/quotas`,
    { withCredentials: true }
  );
  return res.data;
};

export const lastImportPaserelle = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/annonce/passerelle/last_import`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchUserChildren = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/children`,
    { withCredentials: true }
  );
  return res.data;
};

export const exportStats = async (
  startDate = null,
  endDate = null,
  kpi = null,
  childrenSelected = [],
  callback
) => {
  const res = await axios
    .post(
      `${process.env.REACT_APP_API_URL}/user/stats/export${
        kpi ? '/' + kpi.toLowerCase() : ''
      }`,
      {
        start: startDate ? dateWithoutTimezone(startDate) : null,
        end: endDate ? dateWithoutTimezone(endDate) : null,
        childrenSelected
      },
      {
        withCredentials: true
      }
    )
    .then((res) => {
      if (res.status === 200) {
        toast.success('Le téléchargement va commencer');
        const link = document.createElement('a');
        const { base_uri, path, filename } = res.data;
        link.href = base_uri + path;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        callback && callback();
      }
    })
    .catch((err) => {
      toast.error('Une erreur est survenue...');
      callback && callback();
    });
};

export const fetchTagsList = async (key, locven, nature) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/tags?locven=${locven}&nature=${nature}`,
    { withCredentials: true }
  );
  return res.data;
};

export const fetchActivitesList = async (key) => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/activites`, {
    withCredentials: true
  });
  return res.data;
};

export const geocode = async (address) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/locality/geocode`,
    {
      address
    },
    { withCredentials: true }
  );
  return res.data;
};

export const fetchUserEtudes = async ({ pageParam = 1, queryKey }) => {
  const [_, { limit = 20 }] = queryKey;
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/etudes?page=${pageParam}&limit=${limit}`,
    { withCredentials: true }
  );
  return res.data;
};

export const exportEtude = async (etude, callback) => {
  const res = await axios
    .get(`${process.env.REACT_APP_API_URL}/user/etude/${etude}/export/`, {
      withCredentials: true
    })
    .then((res) => {
      if (res.status === 200) {
        toast.success('Le téléchargement va commencer');
        const link = document.createElement('a');
        const { base_uri, path, filename } = res.data;
        link.href = base_uri + path;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        callback && callback();
      }
    })
    .catch((err) => {
      toast.error('Une erreur est survenue...');
      callback && callback();
    });
};

export const anyInvoices = async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/invoices/any`, {
    withCredentials: true
  });
  return res.data;
};

export const anyDevis = async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/devis/any`, {
    withCredentials: true
  });
  return res.data;
};

export const listInvoices = async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/invoices/`, {
    withCredentials: true
  });
  return res.data;
};

export const listDevis = async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/devis/`, {
    withCredentials: true
  });
  return res.data;
};

//stripe only
export const fetchAboData = async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/abonnement/`, {
    withCredentials: true
  });
  return res.data;
};

export const fetchSiteMenu = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/ax/mega_menu.aspx?ajax=true`,
    {
      withCredentials: false
    }
  );
  return res.data;
};

export const fetchSiteMobileMenu = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/ax/ax_menu.aspx`,
    {
      withCredentials: false
    }
  );
  return res.data;
};

export const fetchLastActualiteCompte = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/actualite_compte/last`,
    { withCredentials: true }
  );
  return res.data;
};
