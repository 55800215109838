import { motion } from 'framer-motion';

import styles from './step.module.scss';

const Step = ({
  title,
  description,
  skipStep,
  svg,
  children,
  stepText,
  stepNumber,
  totalStepsNumber
}) => {
  return (
    <motion.div
      initial={{ x: '50vw', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: '-50vw', opacity: 0 }}
      transition={{ type: 'tween', stiffness: 120 }}
      className={styles.content}
    >
      <div className={styles.logoSvg}>{svg}</div>
      <div className={styles.text}>
        <h3>{title}</h3>
        <p>{description}</p>
        {children}
        <p className={styles.skipStep} onClick={skipStep}>
          {stepText}
        </p>
      </div>
    </motion.div>
  );
};

export default Step;
