import { NavLink, useLocation } from 'react-router-dom';

import { useContext, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import useScroll from 'hooks/useScroll';
import { UserContext } from 'contexts/UserContext';
import { availablesMenus } from 'global/utilsMenu';

import styles from './footer.module.scss';

const links = {
  hidden: {
    opacity: 0,
    bottom: -54,
    transition: {
      duration: 0.25,
      when: 'beforeChildren'
    }
  },
  show: {
    opacity: 1,
    bottom: 0,
    transition: {
      duration: 0.25,
      when: 'beforeChildren',
      staggerChildren: 0.08
    }
  }
};
const icon = {
  hidden: {
    opacity: 0,
    bottom: 0,
    transition: {
      duration: 0.34
    }
  },
  show: {
    opacity: 1,
    bottom: 0,
    transition: {
      duration: 0.34
    }
  }
};

const Footer = () => {
  const visible = useScroll(true);
  const { user } = useContext(UserContext);
  const location = useLocation();

  const [menus, setMenus] = useState([]);

  useEffect(() => {
    setMenus(
      availablesMenus(location.pathname, user).filter((m) => m.footer != null)
    );
  }, [user, location.pathname]);

  return (
    <>
      <motion.div
        variants={links}
        initial="hidden"
        animate={visible ? 'show' : 'hidden'}
        className={styles.links}
      >
        {menus.map((menu, key) => (
          <motion.div key={key} variants={icon} className={styles.icon}>
            <NavLink
              className={(navData) =>
                `${menu.isActive() ? `${styles.active}` : ''}`
              }
              to={menu.to}
            >
              {menu.footer}
            </NavLink>
          </motion.div>
        ))}
      </motion.div>
    </>
  );
};

export default Footer;
