import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router';

import { ProgressContext } from 'contexts/ProgressContext';
import { UserContext } from 'contexts/UserContext';

import {
  fetchLastUserProject,
  fetchFavorites,
  fetchLastAlerts,
  fetchLeadAnnonces
} from 'global/queries';
import {
  surfaceFormat,
  fullSurfaceFormat,
  generateSkeletons
} from 'global/util';

import Skeleton from 'components/Skeleton/Skeleton';
import Button from 'components/Button/Button';
import SwiperAnnonce from 'components/Swiper/SwiperAnnonce';
import ProgressSteps from 'components/ProgressSteps/ProgressSteps';
import Step from 'components/Step/Step';

import { AnnonceCardSkeleton } from 'pages/Annonce/Card';
import { ServiceClient } from './Annonceur';

import styles from './home.module.scss';

import { ReactComponent as ProfileSVG } from 'assets/profil2.svg';
import { ReactComponent as ProjectSVG } from 'assets/projet2.svg';
import { ReactComponent as AlertsSVG } from 'assets/alerte2.svg';
import { ReactComponent as ArrowSVG } from 'assets/arrow-right.svg';

const Utilisateur = () => {
  const { skipSettings } = useContext(ProgressContext);
  return !skipSettings ? <UserSteps /> : <UserComplete />;
};

export default Utilisateur;

const UserSteps = () => {
  const {
    isProfileFilled,
    isProjectsFilled,
    isAlertsFilled,
    setSkipSettings,
    skipSettings
  } = useContext(ProgressContext);
  const [totalStepsNumber, setTotalStepsNumber] = useState(0);
  const [arrSteps, setArrSteps] = useState([]);
  const navigate = useNavigate();

  const scroll = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleSkipProgress = () => {
    setSkipSettings(true);
    scroll();
  };

  const steps = [isAlertsFilled, isProfileFilled, isProjectsFilled];

  useEffect(() => {
    setTotalStepsNumber(steps.filter((step) => !step).length);
    let arr = new Array();

    if (
      !skipSettings &&
      (!isProfileFilled || !isProjectsFilled || !isAlertsFilled)
    ) {
      if (!isProfileFilled) arr.push('Profil');
      if (!isProjectsFilled) arr.push('Projet');
      if (!isAlertsFilled) arr.push('Alerte');
    }
    setArrSteps(arr);
  }, [isAlertsFilled, isProfileFilled, isProjectsFilled]);

  return (
    <>
      {totalStepsNumber > 1 && (
        <ProgressSteps
          className={styles.steps}
          steps={arrSteps}
          current={0}
          setActiveStep={(_) => {}}
        ></ProgressSteps>
      )}
      <AnimatePresence exitBeforeEnter>
        {!isProfileFilled ? (
          <Step
            title="Faisons connaissance !"
            description="Pour vous aider dans votre démarche, nous vous invitons d'abord à compléter votre profil (1&nbsp;min), vous pourrez ensuite créer votre premier projet"
            skipStep={handleSkipProgress}
            svg={<ProfileSVG />}
            key="profil"
            stepText="Plus tard"
          >
            <Button onClick={() => navigate('/profil')}>
              <p>C'est parti !</p>
            </Button>
          </Step>
        ) : !isProjectsFilled ? (
          <Step
            title="Votre projet immobilier"
            description="Maximisez vos chances de trouver vos futurs locaux en nous indiquant les détails de votre projet immobilier, nous le transmettrons aux professionnels de votre secteur"
            skipStep={handleSkipProgress}
            svg={<ProjectSVG />}
            key="projet"
            stepText="Plus tard"
          >
            <Button onClick={() => navigate('/nouveau-projet')}>
              <p>Créer un projet</p>
            </Button>
          </Step>
        ) : !isAlertsFilled ? (
          <Step
            title="Votre première alerte"
            description="Activez votre première alerte pour recevoir les futures annonces disponibles en avant-première"
            skipStep={handleSkipProgress}
            svg={<AlertsSVG />}
            key="alerte"
            stepText="Plus tard"
          >
            <Button onClick={() => navigate('/alertes')}>
              <p>Créer une alerte</p>
            </Button>
          </Step>
        ) : (
          <></>
        )}
      </AnimatePresence>
    </>
  );
};

const UserComplete = () => {
  const {
    isProfileFilled,
    isProjectsFilled,
    isAlertsFilled,
    isRequestsFilled,
    isFavoritesFilled
  } = useContext(ProgressContext);
  const navigate = useNavigate();

  const { data: lastProjectData, status: lastProjectStatus } = useQuery(
    'lastProject',
    fetchLastUserProject,
    {
      enabled: !!isProjectsFilled,
      refetchOnWindowFocus: false
    }
  );

  const { data: lastAlertData, status: lastAlertStatus } = useQuery(
    'lastAlert',
    fetchLastAlerts,
    {
      enabled: !!isAlertsFilled,
      refetchOnWindowFocus: false
    }
  );

  const { data: leadAnnonceData, status: leadAnnonceStatus } = useQuery(
    'leadAnnonce',
    fetchLeadAnnonces,
    {
      enabled: !!isRequestsFilled,
      refetchOnWindowFocus: false
    }
  );

  const { data: favoriteData, status: favoriteStatus } = useQuery(
    ['favorites', {}],
    fetchFavorites,
    {
      enabled: !!isFavoritesFilled,
      refetchOnWindowFocus: false
    }
  );

  const flatArrayToAnnonce = (array) => {
    const list = [];
    array &&
      array.map((fav) => {
        let listId = list.map((el) => el.id);
        fav.annonces.map(
          (annonce) => listId.indexOf(annonce.id) == -1 && list.push(annonce)
        );
      });
    return list;
  };

  return (
    <>
      <ServiceClient />
      {!isProfileFilled && (
        <div className={styles.tuile + ' ' + styles.profil}>
          <div className={styles.text}>
            <ProfileSVG />
            <div className={styles.wrapped}>
              <div className={styles.titleUser}>Votre profil</div>
              <p>
                Pour vous aider dans votre démarche, nous vous invitons d'abord
                à compléter votre profil (1 min), vous pourrez ensuite créer
                votre premier projet.
              </p>
              <Button onClick={() => navigate('/profil')}>C'est parti</Button>
            </div>
          </div>
        </div>
      )}

      <div className={styles.tuile + ' ' + styles.projet}>
        <div className={styles.titleUser}>Vos projets immobiliers</div>
        <p>
          {!!isProjectsFilled && (
            <>Suivez et modifiez votre dernier projet ci-dessous. </>
          )}
          Retrouvez l'ensemble de vos projets dans l'onglet dédié.
        </p>

        {!!isProjectsFilled ? (
          <>
            {lastProjectStatus === 'loading' ? (
              <Skeleton height="78px" width="100%" />
            ) : (
              <>
                {lastProjectData &&
                  lastProjectData?.slice(0, 2)?.map((project) => (
                    <Link
                      key={project.id}
                      to={`/projets/${project.id}`}
                      className={styles.tuile_inlinelnk}
                    >
                      <div className={styles.gr + ' ' + styles.name}>
                        {project.nom || 'nom par défaut'}
                      </div>
                      <div className={styles.ico}>
                        <ArrowSVG />
                      </div>
                    </Link>
                  ))}
                {lastProjectData && lastProjectData.length == 1 && (
                  <Button onClick={() => navigate('/nouveau-projet')}>
                    Créer un projet
                  </Button>
                )}
                {lastProjectData && lastProjectData.length > 1 && (
                  <Button onClick={() => navigate('/projets')}>
                    Vos projets
                  </Button>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div className={styles.no_result + ' ' + styles.no_result_smaller}>
              Aucun projet pour le moment, cliquez sur le boutton ci-dessous
              pour en créer un nouveau.
            </div>
            <Button onClick={() => navigate('/nouveau-projet')}>
              Créer un projet
            </Button>
          </>
        )}
      </div>

      <div className={styles.tuile + ' ' + styles.alerte}>
        <div className={styles.titleUser}>Vos alertes</div>
        <p>
          Votre dernière alerte active ci-dessous. Modifiez la ou configurez-en
          d'autres.
        </p>
        {!!isAlertsFilled ? (
          <>
            {lastAlertStatus === 'loading' ? (
              <Skeleton height="78px" width="100%" />
            ) : (
              <>
                {lastAlertData &&
                  lastAlertData?.slice(0, 2)?.map((alert) => (
                    <Link
                      key={alert.id}
                      to={`/alertes/${alert.id}/settings`}
                      className={styles.tuile_inlinelnk}
                    >
                      <div className={styles.gr}>
                        <div className={styles.name}>
                          {alert.nom || 'nom par défaut'}
                        </div>
                        {(alert.min_surf || alert.max_surf) && (
                          <div className={styles.surf}>
                            {fullSurfaceFormat(
                              alert.min_surf,
                              alert.max_surf
                            ).replace(' -', 'm² à')}
                          </div>
                        )}
                        <div className={styles.creation}>
                          Créée le{' '}
                          {new Date(alert.date_create).toLocaleDateString(
                            'fr',
                            {
                              day: 'numeric',
                              month: 'numeric',
                              year: 'numeric'
                            }
                          )}
                        </div>
                      </div>
                      <div className={styles.ico}>
                        <ArrowSVG />
                      </div>
                    </Link>
                  ))}
                {lastAlertData && lastAlertData.length == 1 && (
                  <Button onClick={() => navigate('/nouvelle-alerte')}>
                    Créer une alerte
                  </Button>
                )}
                {lastAlertData && lastAlertData.length > 1 && (
                  <Button onClick={() => navigate('/alertes')}>
                    Vos alertes
                  </Button>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div className={styles.no_result + ' ' + styles.no_result_smaller}>
              Aucune alerte pour le moment, cliquez sur le boutton ci-dessous
              pour en créer une nouvelle.
            </div>
            <Button onClick={() => navigate('/nouvelle-alerte')}>
              Créer une alerte
            </Button>
          </>
        )}
      </div>

      <div className={styles.tuile + ' ' + styles.contact}>
        <div className={styles.titleUser}>Vos demandes de contact</div>
        <p>
          Voici l'annonce pour laquelle vous avez envoyé une demande de contact.
          Si vous cherchez toujours des locaux, consultez les offres disponibles
          sur <a href={process.env.REACT_APP_SITE_URL}>Geolocaux.com</a>.
        </p>
        {!!isRequestsFilled ? (
          <>
            {leadAnnonceStatus === 'loading' &&
              generateSkeletons(<AnnonceCardSkeleton />, 4, 'row', '16px')}
            {leadAnnonceStatus === 'success' && (
              <SwiperAnnonce annonces={leadAnnonceData} navigation />
            )}
          </>
        ) : (
          <div className={styles.no_result}>Aucune demande de contact</div>
        )}
      </div>

      <div className={styles.tuile + ' ' + styles.favori}>
        <div className={styles.titleUser}>Vos annonces favorites</div>
        <p>
          Enregistrez vos annonces favorites parmi les offres disponibles sur{' '}
          <a href={process.env.REACT_APP_SITE_URL}>Geolocaux.com</a>.
        </p>

        {!!isFavoritesFilled ? (
          <>
            {favoriteStatus === 'loading' &&
              generateSkeletons(<AnnonceCardSkeleton />, 4, 'row', '16px')}
            {favoriteStatus === 'success' && (
              <SwiperAnnonce
                annonces={flatArrayToAnnonce(favoriteData)}
                navigation
              />
            )}
          </>
        ) : (
          <div className={styles.no_result}>Aucune annonce favorite</div>
        )}
      </div>
    </>
  );
};
