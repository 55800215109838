import Router from './pages/Router';
import UserContextProvider from './contexts/UserContext';
import ScrollToTop from './components/ScrollToTop';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'app.scss';
import ProgressContextProvider from 'contexts/ProgressContext';
import ContactContextProvider from 'contexts/ContactContext';
import { Application } from 'react-rainbow-components';

// import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient();

/* rainbow components */
const themeRainbow = {
  rainbow: {
    palette: {
      brand: '#17243f'
    }
  }
};

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <ProgressContextProvider>
            <ContactContextProvider>
              <Application theme={themeRainbow}>
                <ToastContainer
                  position="top-center"
                  pauseOnFocusLoss={false}
                  autoClose={2000}
                  limit={5}
                  transition={Slide}
                />
                <ScrollToTop />
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                <Router />
              </Application>
            </ContactContextProvider>
          </ProgressContextProvider>
        </UserContextProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
