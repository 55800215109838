import { createContext, useState } from 'react';

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState({});
  const [isPro, setIsPro] = useState(false);
  const [isBroker, setIsBroker] = useState(false);
  const [hasPartenaire, setHasPartenaire] = useState(false);
  const [enabledTransaction, setEnabledTransaction] = useState(false);
  const [hasEtude, setHasEtude] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const [hasChildrenPartenaire, setHasChildrenPartenaire] = useState(false);
  const [canAddAnnonce, setCanAddAnnonce] = useState(false);
  const [canEditAnnonce, setCanEditAnnonce] = useState(false);
  const [stripeBilling, setStripeBilling] = useState(false);
  const [canSubscribeAbo, setCanSubscribeAbo] = useState(false);
  const [lastDisabledAbo, setLastDisabledAbo] = useState(null);
  const [refetchUser, setRefetchUser] = useState(false);
  const [contactTel, setContactTel] = useState(
    process.env.REACT_APP_TEL_CONTACT
  );
  const [contactPicture, setContactPicture] = useState(
    process.env.PUBLIC_URL + '/images/consultant/quentin.jpg'
  );
  const [contactName, setContactName] = useState('service client');
  const [contactServiceClient, setContactServiceClient] = useState(true);

  return (
    <UserContext.Provider
      value={{
        isLogged,
        setIsLogged,
        user,
        setUser,
        isPro,
        setIsPro,
        isBroker,
        setIsBroker,
        hasPartenaire,
        setHasPartenaire,
        enabledTransaction,
        setEnabledTransaction,
        hasChildren,
        setHasChildren,
        hasChildrenPartenaire,
        setHasChildrenPartenaire,
        canAddAnnonce,
        setCanAddAnnonce,
        canEditAnnonce,
        setCanEditAnnonce,
        refetchUser,
        setRefetchUser,
        contactTel,
        setContactTel,
        contactPicture,
        setContactPicture,
        contactName,
        setContactName,
        contactServiceClient,
        setContactServiceClient,
        hasEtude,
        setHasEtude,
        stripeBilling,
        setStripeBilling,
        canSubscribeAbo,
        setCanSubscribeAbo,
        lastDisabledAbo,
        setLastDisabledAbo
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
