import styles from './main.module.scss';

const Main = ({ children, className }) => {
  return (
    <div
      className={
        styles.wrapper + ((className || '') != '' ? ` ${className}` : '')
      }
    >
      {children}
    </div>
  );
};

export default Main;
