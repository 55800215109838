import { useEffect, useContext, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ContactContext } from 'contexts/ContactContext';
import { subjectOptions, returnMessages } from 'global/util';
import { motion } from 'framer-motion';
import { UserContext } from 'contexts/UserContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormInput } from 'components/Form/Form';
import Button from 'components/Button/Button';

import axios from 'axios';
import { FormSelect } from 'components/Form/Form';
import formStyles from '../../components/Form/form.module.scss';
import styles from './contactForm.module.scss';

const formContact = {
  hidden: {
    opacity: 0,
    y: '100%',
    transition: {
      duration: 0.45
    }
  },
  show: {
    opacity: 1,
    y: '0',
    transition: {
      duration: 0.45
    }
  }
};

const validationSchema = yup.object().shape({
  nom: yup.string().required('Veuillez renseigner un nom'),
  tel: yup.string().required('Veuillez renseigner un numéro de téléphone'),
  email: yup
    .string()
    .email('Veuillez renseigner un email valide')
    .required('Veuillez renseigner un mail'),
  message: yup.string().required('Veuillez renseigner un message')
});

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const { user, isPro, isBroker, contactTel } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState([]);

  const { showFormContact, setShowFormContact, message, setMessage } =
    useContext(ContactContext);

  // useEffect(() => {
  //   if (errors) {
  //     const errorMessages = Object.values(errors);
  //     errorMessages.map((msg) => toast.error(msg.message));
  //   }
  // }, [errors]);

  useEffect(() => {
    errorMessages?.length > 0 && toast.error(errorMessages[0]);
  }, [errorMessages]);

  useEffect(() => {
    setErrorMessages(returnMessages(Object.values(errors)).flat());
  }, [errors]);

  useEffect(() => {
    if (showFormContact) {
      document.body.style.overflow = 'hidden';
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      document.body.style.overflow = '';
      setMessage('');
    }
  }, [showFormContact]);

  const closeForm = () => {
    setShowFormContact(false);
    setMessage('');
  };

  const onSubmit = (data) => {
    try {
      const sendMessage = () => {
        axios
          .post(`${process.env.REACT_APP_API_URL}/message/send`, data, {
            withCredentials: true
          })
          .then((rep) => {
            toast.success('Message envoyé!');
            setShowFormContact(false);
          });
      };
      sendMessage();
    } catch (err) {
      toast.error('Une erreur est survenue...');
    } finally {
      reset();
    }
  };

  const handleChange = (event) => {
    if (event.value == 'AUTRE')
      setMessage(
        'Je souhaiterais obtenir des informations à propos de ...\n\n' +
          user.prenom +
          ' ' +
          user.nom
      );
    else
      setMessage(
        subjectOptions.filter((s) => s.value == event.value)[0].label +
          '\n\n' +
          user.prenom +
          ' ' +
          user.nom
      );
  };

  return (
    <motion.div
      variants={formContact}
      initial="hidden"
      animate={showFormContact ? 'show' : 'hidden'}
      className={styles.formContact}
    >
      <div className={styles.formTitle}>
        Appelez {user.commercial?.prenom ? user.commercial?.prenom : 'nous'}
      </div>
      <Button className={['blueBg']}>{contactTel}</Button>
      <div className={`${styles.formTitle} ${styles.formTitleBis}`}>
        Laissez-nous un message !
      </div>
      <form className={styles.modalContent} onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          name="nom"
          label="Nom"
          placeholder="Nom"
          //defaultValue={user?.nom}
          register={register}
          className={`${errors.nom && formStyles.error} ${styles.inputs_ctc}`}
        />
        <FormInput
          name="tel"
          placeholder="Téléphone"
          label="Tel."
          //defaultValue={user?.tel}
          className={`${errors.tel && formStyles.error} ${styles.inputs_ctc}`}
          register={register}
        />
        <FormInput
          name="email"
          type="email"
          placeholder="Email"
          label="Email"
          //defaultValue={user?.email}
          register={register}
          className={`${errors.email && formStyles.error} ${styles.inputs_ctc}`}
        />
        {!isPro && !isBroker && (
          <FormSelect
            id="objet"
            label="Sujet "
            options={subjectOptions}
            name="objet"
            placeholder="Sujet du contact"
            className={styles.inputs_ctc}
            onChange={handleChange}
          />
        )}
        <div className={styles.input_textarea}>
          <textarea
            id="message"
            name="message"
            placeholder={
              !isPro && !isBroker
                ? 'Veuillez sélectionner un sujet'
                : "Quel est l'objet de votre demande ?"
            }
            {...register('message')}
            defaultValue={message}
            className={errors.message && formStyles.error}
          />
        </div>
        <Button className={['blueBg']}>Envoyer un email</Button>
      </form>
      <div className={styles.close} onClick={closeForm}>
        <span className={styles.close_text}>Fermer</span>
        <MdClose className={styles.closeModalButton} />
      </div>
    </motion.div>
  );
};
export default ContactForm;
