import { useEffect } from 'react';
import { MdDone } from 'react-icons/md';

import styles from './pwdStrength.module.scss';

const PwdStrength = ({
  pwd,
  minChar = 8,
  lowercase = true,
  uppercase = true,
  number = true,
  symbol = true,
  className,
  setPwdValid
}) => {
  useEffect(() => {
    let valid_pwd = true;
    if (pwd == null || pwd.trim().length < minChar) valid_pwd = false;
    else if (lowercase && !new RegExp(/[a-z]/).test(pwd)) valid_pwd = false;
    else if (uppercase && !new RegExp(/[A-Z]/).test(pwd)) valid_pwd = false;
    else if (number && !new RegExp(/[0-9]/).test(pwd)) valid_pwd = false;
    else if (symbol && !new RegExp(/[\W]/).test(pwd)) valid_pwd = false;

    setPwdValid(valid_pwd);
  }, [pwd]);

  return (
    <ul className={styles.pwdStrength + ' ' + (className || '')}>
      <li
        className={
          pwd != null && pwd.trim().length >= minChar ? styles.valid : undefined
        }
      >
        <MdDone />
        {minChar} caractère{minChar > 1 ? 's' : ''} minimum
      </li>
      {lowercase && (
        <li
          className={new RegExp(/[a-z]/).test(pwd) ? styles.valid : undefined}
        >
          <MdDone />1 lettre minuscule
        </li>
      )}
      {uppercase && (
        <li
          className={new RegExp(/[A-Z]/).test(pwd) ? styles.valid : undefined}
        >
          <MdDone />1 lettre majuscule
        </li>
      )}
      {number && (
        <li
          className={new RegExp(/[0-9]/).test(pwd) ? styles.valid : undefined}
        >
          <MdDone />1 chiffre
        </li>
      )}
      {symbol && (
        <li className={new RegExp(/[\W]/).test(pwd) ? styles.valid : undefined}>
          <MdDone />1 caractère spécial
        </li>
      )}
    </ul>
  );
};

export default PwdStrength;
