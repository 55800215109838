import { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';

import Annonceur from './Annonceur';
import Utilisateur from './Utilisateur';
import PageWrapper from 'components/PageWrapper/PageWrapper';

import styles from './home.module.scss';
import UpperSection from 'components/Layout/UpperSection';
import Main from 'components/Layout/Main';

const Home = () => {
  const { isPro } = useContext(UserContext);

  return (
    <PageWrapper>
      <Main className={styles.mainOver}>
        <UpperSection className={styles.upperSection}>
          <h1>{isPro ? 'Mon Espace Pro' : 'Mon compte utilisateur'}</h1>
          <p>
            {isPro
              ? `Pilotez facilement la diffusion de vos annonces sur Geolocaux.`
              : `Gérer vos projets, vos alertes, vos favoris et retrouver l'ensemble de vos demandes envoyées via Geolocaux.com`}
          </p>
        </UpperSection>
        <div className={styles.container}>
          {isPro ? <Annonceur /> : <Utilisateur />}
        </div>
      </Main>
    </PageWrapper>
  );
};

export default Home;
